body {
  font-family: Montserrat-Regular;
  color: $darkTextColor;
  overflow-x: hidden;
}

p {
  margin-bottom: 0;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

button:focus {
  outline: 0 !important;
}

.text-dark {
  color: $darkTextColor;
}

.primary_color {
  color: $primaryColor;
}

.secondary_light_color {
  color: $secondaryColor;
}

.text-lignt {
  color: $lightTextColor;
}

.danger_color {
  color: #f44336;
}

button.btn-primary {
  font-family: Montserrat-Bold;
  background-color: $primaryColor !important;
  border: 0;
  border-color: 0 !important;
  border-radius: 28px;
  padding: 12px 5em 12px 5em;
}

.light-btn {
  font-family: Montserrat-SemiBold;
  background-color: $secondaryColor !important;
  border: 0;
  border-color: 0 !important;
  border-radius: 28px;
  color: white;
}

.light-btn-border {
  font-family: Montserrat-SemiBold;
  background-color: white;
  border: 2px solid $secondaryColor;
  border-radius: 28px;
  color: $secondaryColor;
}

.borderRaduis {
  outline: none !important;
  border-radius: 28px !important;
}

legend {
  width: auto !important;
}
.MuiFormLabel-root.MuiInputLabel-outlined {
  background-color: white;
  padding-right: 5px;
  padding-left: 5px;
}

.card_style {
  border-radius: 8px;
  border: 0;
  box-shadow: 0px 2px 11px 0px rgba(194, 194, 194, 0.7);
}

.bold_font {
  font-family: Montserrat-Bold;
}

.medium_font {
  font-family: Montserrat-Medium;
}
.semi_bold_font {
  font-family: Montserrat-SemiBold;
}

.regular_font {
  font-family: Montserrat-Regular;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid #7f323f !important;
}
.page-item.active .page-link {
  border-color: $secondaryColor;
  background-color: $secondaryColor;
  font-family: Montserrat-Medium;
}

.page-item .page-link {
  color: $primaryColor;
  font-family: Montserrat-Medium;
}

.notFoundImage {
  height: 20em;
}
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primaryColor;
}
.color-black {
  color: black;
}

.color-grey-dark {
  color: $lightTextColor;
}

.rotatey-180 {
  transform: rotateY(180deg);
}

button {
  outline: none;
}

.iconPointer {
  cursor: pointer;
}
.width-75 {
  width: 75%;
}

.txt-white {
  color: #fff;
}
.modal-label {
  .MuiFormLabel-root {
    background-color: $modalPaperColor;
  }
}
.word-break {
  word-break: break-all;
}
.error_msg{
  color: red;
  font-family: inherit;
}
h1{
  font-size:2.6rem !important}
h2{
font-size:2.4rem !important
}
p{
  font-size:1.3rem !important
}
@media screen and (min-width:601px) and (max-width:1200px){
  html{
    font-size:15px !important
  }
}
@media screen and (max-width:600px){
  html{
    font-size:15px !important
  }
  p{
    font-size: 1rem !important;
  }
}