/* ============= Colors ============= */
$primaryColor: #86364e;
$darkPrimaryColor: #6c122f;
$secondaryColor: #ea8685;
$darkTextColor: rgba(62, 62, 62, 1);
$lightTextColor: rgba(123, 123, 123, 1);
$lightGrey: rgba(219, 219, 219, 1);
$lightBorder: rgba(224, 233, 239, 1);
$PalePink: rgba(255, 218, 218, 1);
$shadowSecondaryColor: rgba(234, 134, 133, 0.1);
$shadowLightTextColor: rgba(123,123,123, 0.1);

$error: #ff3739;
$success: #3bd35f;
$modalPaperColor: #f2f2f2;
/* ============= Fonts ============= */
$fontExtentions: "eot", "ttf", "woff", "woff2";
$fontFamilies: "Montserrat-Bold", "Montserrat-Medium", "Montserrat-Regular",
  "Montserrat-SemiBold", "Montserrat-SemiBold";
$ArabicFontFamilies: "NeoSansArabic", "NeoSansArabicBold", "NeoSansArabicMedium";
