.rtl {
  text-align: right;
  font-family: NeoSansArabic;
  .bold_font {
    font-family: NeoSansArabicBold;
  }

  .medium_font,
  .semi_bold_font {
    font-family: NeoSansArabicMedium;
  }

  .regular_font {
    font-family: NeoSansArabic;
  }
  .hero-section {
    margin-right: auto;
  }
  label,
  input::-ms-input-placeholder,
  input::-moz-placeholder {
    font-family: NeoSansArabic;
  }

  .news_container {
    margin-right: auto;
    margin-left: 0em;
    .slider_container {
      padding-left: 0;
      position: relative;
      &::before {
        border-radius: 0px 14px 14px 0px;
        left: 0;
        right: auto;
      }
      &::after {
        left: 0;
        right: auto;
        transform: rotateY(180deg);
      }
      .owl-carousel {
        direction: ltr;
        transform: rotateY(180deg);
        .MuiCardContent-root {
          transform: rotateY(-180deg);
        }
        .news_image {
          transform: rotateY(-180deg);
        }
      }
    }
    .circleNavBtn {
      transform: rotate(180deg);
    }
  }

  .allProducts {
    margin-right: auto;
    margin-left: 0em;
    .productsContainer {
      padding-left: 0;
      position: relative;
      &::before {
        border-radius: 0px 14px 14px 0px;
        left: 0;
        right: auto;
      }
      &::after {
        left: 0;
        right: auto;
        transform: rotateY(180deg);
      }
    }
  }

  .features_section {
    &::before {
      left: auto;
      right: 0;
      transform: rotateY(180deg);
    }
    .darkCirclesBg {
      &::before {
        transform: rotateY(180deg);
      }
    }
    .features_cards_row {
      margin-right: 0 !important;
      margin-left: 4em;
      .features_cards_col:nth-child(1) {
        .features_card {
          padding-top: 1em;
          padding-bottom: 1em;
        }
      }
    }
  }

  .reviews_container {
    &::before {
      border-radius: 0px 14px 14px 0px;
      right: auto;
      left: 0;
    }
    &::after {
      border-radius: 0px 14px 14px 0px;
      right: auto;
      left: 0;
    }
    .reviews_slider_container {
      border-radius: 14px 0 0 14px;
      .owl-carousel {
        direction: ltr;
        .owl-item {
          direction: rtl;
        }
        .owl-dots {
          direction: rtl;
        }
        &.owl-theme {
          .owl-nav {
            .owl-prev {
              left: auto;
              right: 0;
              transform: rotate(180deg);
            }

            .owl-next {
              left: 0;
              right: auto;
              transform: rotate(180deg);
            }
          }
        }
      }
      .review_writer_info {
        left: -14em;
        right: auto;
        top: 12em;
        text-align: right;
      }
      .profile_background {
        &::before {
          transform: rotateY(180deg);
        }
        .reviews_profile_img {
          right: 3em;
          left: auto;
        }
      }
    }
  }

  .question_body {
    &::before {
      right: 0;
      left: auto;
    }
  }

  .about_numbers {
    .border_numbers {
      border-left: 2px solid #ffffff36;
      border-right: none;
    }
  }

  .navbar .dropdown:hover > .dropdown-menu {
    right: 0 !important;
    text-align: right !important;
  }
  .login-wrapper {
    &::before {
      right: auto;
      left: 0;
      transform: rotateY(180deg);
    }
    .registration {
      &::before {
        right: auto;
        left: 0;
      }
      &::after {
        right: auto;
        left: 0;
        transform: rotateY(180deg);
      }
    }
  }
  .registration {
    &::before {
      right: auto;
      left: 0;
    }
    &::after {
      right: auto;
      left: 0;
      transform: rotateY(180deg);
    }
  }

  .help-tip {
    left: 5px;
    right: auto;
    &:hover ul {
      text-align: right !important;
      left: 0 !important;
      right: auto !important;
      position: absolute !important;
      z-index: 9999 !important;
    }
  }

  .country-list {
    direction: rtl;
    text-align: right;
  }

  .react-tel-input {
    position: relative;
    &::before {
      content: "الهاتف";
      position: absolute;
      top: -8px;
      right: 19px;
      right: 10px;
      display: block;
      background: white;
      padding: 0 5px;
      font-size: 0.8rem;
      width: 34px;
      z-index: 999;
      color: #6b6b6b;
    }
    .form-control + div:before {
      display: none;
    }
  }

  .product1 {
    direction: rtl !important;
    transform: rotateY(180deg);
    .Recruitment {
      transform: rotateY(180deg);
      text-align: right;
    }
  }

  .MuiListItem-button {
    &:hover {
      border-right: 2px solid white;
      border-left: none;
      background: linear-gradient(
        80deg,
        rgba(74, 144, 226, 0) 0%,
        rgba(255, 255, 255, 0.19) 100%
      );
    }
  }

  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    border-right: 2px solid white;
    border-left: none;
    background: linear-gradient(
      80deg,
      rgba(74, 144, 226, 0) 0%,
      rgba(255, 255, 255, 0.19) 100%
    );
  }
}

.MuiDialog-paper[dir="rtl"] {
  text-align: right;
}

.MuiMenuItem-root[dir="rtl"],
.MuiListItemText-root[dir="rtl"] span {
  font-family: NeoSansArabic !important;
}
